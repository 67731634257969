import { arrify } from '@condo/helpers';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';
import { useWindowSize } from 'vue-window-size';

export const useViewPortHeight = () => {
    const { height: windowHeight } = useWindowSize();
    return computed(() => {
        const NAVBAR_HEIGHT = 56;
        const REVIEW_PANEL = 62;
        return `${windowHeight.value - NAVBAR_HEIGHT - REVIEW_PANEL}px`;
    });
};

export const useTimeout = (timeMs: number) => {
    const timeoutFn = ref(null);
    const timeoutElapsed = ref(false);

    onMounted(() => {
        timeoutFn.value = setTimeout(() => {
            timeoutElapsed.value = true;
        }, timeMs);
    });

    onBeforeUnmount(() => {
        if (timeoutFn.value) {
            clearTimeout(timeoutFn.value);
        }
    });

    return timeoutElapsed;
};

export const useRouteParams = (routeLike?: RouteLocationNormalizedLoaded) => {
    const route = routeLike ?? useRoute();

    const getRouteParamArray = <T extends number | string>(key: string, caster: (arg: any) => T, fallback?: T[]): T[] => {
        const params = new URLSearchParams(decodeURIComponent(route.fullPath.split('?')[1]).replaceAll('&#x3D;', '='));

        const value = params.getAll(key).filter(Boolean);

        if (value?.length) {
            return arrify(value).map(val => caster(val));
        }

        return fallback ?? [];
    };

    const getRouteParam = <T extends number | string | boolean>(key: string, caster: (arg: any) => T): T => {
        const params = new URLSearchParams(decodeURIComponent(route.fullPath.split('?')[1]).replaceAll('&#x3D;', '='));
        const value = params.get(key);

        if (value) {
            return caster(value);
        }
    };

    return { getRouteParamArray, getRouteParam };
};
