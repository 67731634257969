import { fetchBootstrapData, getCostTypeMappings, getDictionaries } from '@/api/basecamp.api';
import { processUsersList, showErrorMessage } from '@/shared';
import type { IActionParams } from '@/types';
import type { ICockpitBootstrapData, IDictionaries, TConfigCostTypeMapping } from '@condo/domain';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface IDictionariesStore {
    dictionaries?: Omit<IDictionaries, 'location'>;
    costTypeMappings?: Record<string, TConfigCostTypeMapping>;
    bootstrapData: ICockpitBootstrapData;
}

const state = {};

const getters: GetterTree<IDictionariesStore, any> = {
    dictionaries(state: IDictionariesStore) {
        return state.dictionaries;
    },
    costTypeMappings(state: IDictionariesStore) {
        return state.costTypeMappings;
    },
    bootstrapData(state: IDictionariesStore): ICockpitBootstrapData {
        return state.bootstrapData;
    },
};

const actions: ActionTree<IDictionariesStore, any> = {
    async getCostTypeMappings({ commit }: IActionParams<IDictionariesStore>) {
        const costTypeMappings = await getCostTypeMappings();
        commit('setCostTypeMappings', costTypeMappings);
    },
    async getDictionaries({ commit }: IActionParams<IDictionariesStore>) {
        return getDictionaries()
            .then(response => {
                commit('setDictionaries', response);

                return response;
            })
            .catch(error => {
                showErrorMessage({ message: `Error during fetching dictionaries: ${error.message}` });
            });
    },
    /**
     * One action to rule them all. Before this action we were making up to 8 requests just to load the page for sourcing
     * or all estates list.
     */
    async fetchBootstrapData({ commit }): Promise<ICockpitBootstrapData> {
        const data = await fetchBootstrapData();
        /*Users List processing*/
        const { usersByContact, usersObject, companyFunctions } = processUsersList(data.users);
        commit('userCompanyFunction/setUserCompanyFunctions', companyFunctions, { root: true });
        commit('users', usersObject, { root: true });
        commit('setUsersByContactId', usersByContact, { root: true });

        /*Processing dictionaries*/
        commit('dictionaries/setDictionaries', data.dictionaries, { root: true });

        /*Back-Up of all data*/
        commit('bootstrapData', data);

        return data;
    },
};

const mutations: MutationTree<IDictionariesStore> = {
    setCostTypeMappings(state: IDictionariesStore, costTypeMappings: Record<string, TConfigCostTypeMapping>) {
        state.costTypeMappings = costTypeMappings;
    },
    setDictionaries(state: IDictionariesStore, dictionaries: IDictionaries) {
        state.dictionaries = dictionaries;
    },
    bootstrapData(state, bootstrapData: ICockpitBootstrapData) {
        state.bootstrapData = bootstrapData;
    },
};

export const dictionariesStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
