import { getMFHPortfolioProcess } from '@/api/mfh/mfh-portfolio-process.api';
import { useRouteParams } from '@/hooks/util.hooks';
import { CCSRouteMap, MFHPortfolioProcessType, resolveRoutePath } from '@condo/domain';
import { RouteRecordRaw } from 'vue-router';

export const notificationRoute: RouteRecordRaw = {
    ...CCSRouteMap['notification'],
    component: () => null,
    async beforeEnter(to, _from, next) {
        const { getRouteParam } = useRouteParams(to);
        const t = getRouteParam('t', String);

        switch (t) {
            case 'note-mfh-portfolio-financing-process': {
                const mfhPortfolioFinancingProcessId = getRouteParam('mfhPortfolioFinancingProcessId', String);
                if (mfhPortfolioFinancingProcessId) {
                    const process = await getMFHPortfolioProcess({
                        processType: MFHPortfolioProcessType.Financing,
                        processId: +mfhPortfolioFinancingProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh-portfolio.financing.overview'], {
                            params: { processId: mfhPortfolioFinancingProcessId, mfhPortfolioId: process.mfhPortfolioId.toString() },
                        }),
                    );
                    return;
                }

                break;
            }
            case 'note-mfh-portfolio-purchasing-process': {
                const mfhPortfolioPurchasingProcessId = getRouteParam('mfhPortfolioPurchasingProcessId', String);
                if (mfhPortfolioPurchasingProcessId) {
                    const process = await getMFHPortfolioProcess({
                        processType: MFHPortfolioProcessType.Purchasing,
                        processId: +mfhPortfolioPurchasingProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh-portfolio.purchasing.overview'], {
                            params: { processId: mfhPortfolioPurchasingProcessId, mfhPortfolioId: process.mfhPortfolioId.toString() },
                        }),
                    );
                    return;
                }
                break;
            }
            case 'note-mfh-portfolio-selling-process': {
                const mfhPortfolioSellingProcessId = getRouteParam('mfhPortfolioSellingProcessId', String);
                if (mfhPortfolioSellingProcessId) {
                    const process = await getMFHPortfolioProcess({
                        processType: MFHPortfolioProcessType.Selling,
                        processId: +mfhPortfolioSellingProcessId,
                    });

                    next(
                        resolveRoutePath(CCSRouteMap['one.mfh-portfolio.selling.overview'], {
                            params: { processId: mfhPortfolioSellingProcessId, mfhPortfolioId: process.mfhPortfolioId.toString() },
                        }),
                    );
                    return;
                }
                break;
            }
            default:
                next('/not-found');
        }
    },
};
