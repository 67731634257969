import type { ICockpitStore } from '@/store';
import { type ContactExpandOpts, getStrippedCCSRoutes } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';
import type { Store } from 'vuex';

const CCSRouteMap = getStrippedCCSRoutes();

export const createContactRoutes = (store: Store<ICockpitStore>): RouteRecordRaw => ({
    ...CCSRouteMap.contactDetail,
    component: () => import('@/components/contact/ContactDetail.vue'),
    props: route => ({ contactId: +route.params.contactId }),
    redirect: params => `${params.fullPath}/${CCSRouteMap['contactDetail.info'].path}`,
    async beforeEnter(to, from, next) {
        await store.dispatch('contacts/getOneContact', {
            contactId: to.params.contactId,
            expand: {
                select: [
                    'contactId',
                    'salutation',
                    'academicRank',
                    'userId',
                    'firstname',
                    'lastname',
                    'contactType',
                    'phoneNumber',
                    'email',
                    'homepageUrl',
                    'birthday',
                    'subCompany',
                ],
                expand: {
                    avatarMediaFile: {
                        select: ['mediaFileId', 'path'],
                        expand: {
                            variants: {
                                select: ['mediaFileId', 'path', 'variant'],
                            },
                        },
                    },
                    location: {
                        select: ['locationId', 'longitude', 'latitude', 'street', 'houseNumber', 'zipcode', 'city', 'quarter'],
                    },
                    employment: {
                        select: ['contactPersonId', 'companyId', 'companyRole', 'isMainContact'],
                        expand: {
                            company: {
                                select: ['companyId', 'name'],
                            },
                        },
                    },
                    accounting: {
                        select: [
                            'accountingId',
                            'contactPersonId',
                            'defaultCostType',
                            'datevCreditorId',
                            'datevDebtorId',
                            'datevBookKeepingAccount',
                            'taxId',
                            'vatId',
                        ],
                    },
                },
            } as ContactExpandOpts,
        });
        next();
    },
    children: [
        {
            ...CCSRouteMap['contactDetail.info'],
            components: {
                default: () => import('@/components/contact/ContactPersonalData.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['contactDetail.estates'],
            components: {
                default: () => import('@/components/contact/ContactEstates.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['contactDetail.documents'],
            components: {
                default: () => import('@/components/contact/ContactDocuments.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['contactDetail.invoices'],
            components: {
                default: () => import('@/components/contact/ContactInvoices.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['contactDetail.calendar'],
            components: {
                default: () => import('@/components/contact/ContactCalendar.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['contactDetail.tasks'],
            components: {
                default: () => import('@/components/ComingSoon.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['contactDetail.reviewRequests'],
            components: {
                default: () => import('@/components/review-process/ReviewRequestTable.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['contactDetail.jobs'],
            components: {
                default: () => import('@/views/Jobs.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['contactDetail.reviewProcesses'],
            components: {
                default: () => import('@/components/review-process/ReviewProcessesList.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['contactDetail.notificationSettings'],
            components: {
                default: () => import('@/components/notification/NotificationSettings.vue'),
            },
            props: false,
        },
    ],
});
