import { getStrippedCCSRoutes } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';

const CCSRouteMap = getStrippedCCSRoutes();

export const createCompanyRoutes = (): RouteRecordRaw => ({
    ...CCSRouteMap['one.company'],
    redirect: params => `${params.fullPath}/${CCSRouteMap['company.details'].path}`,
    component: () => import('@/components/company/OneCompany.vue'),
    props: route => ({ companyId: +route.params.companyId }),
    children: [
        {
            ...CCSRouteMap['company.details'],
            components: {
                default: () => import('@/components/company/CompanyDetails.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['company.estates'],
            components: {
                default: () => import('@/components/company/CompanyEstates.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['company.mfhs'],
            components: {
                default: () => import('@/components/company/CompanyMfhs.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['company.contacts'],
            components: {
                default: () => import('@/components/company/CompanyContacts.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['company.documents'],
            components: {
                default: () => import('@/components/company/CompanyDocuments.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['company.invoices'],
            components: {
                default: () => import('@/components/company/CompanyInvoices.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['company.contractor-capacity'],
            components: {
                default: () => import('@/components/company/CompanyContractorCapacity.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['company.contractor-pricing'],
            components: {
                default: () => import('@/components/company/CompanyContractorPricing.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['company.history'],
            components: {
                default: () => import('@/components/ComingSoon.vue'),
            },
            props: true,
        },
    ],
});
