import type {
    EstateExpandOpts,
    FormSchema,
    IBaseDDQuestion,
    IDDAnswer,
    IDDAnswerUpsertParams,
    IDDQuestion,
    IEstate,
    ILocation,
    ILocationDTO,
    IMFH,
    IMFHBuilding,
    IMFHPortfolio,
    IMediaFile,
    IMfhFilter,
    IPaginationFields,
    IUpdateRentRollDDParams,
    LocationExpandOpts,
    MFHBuildingExpandOpts,
    MFHExpandOpts,
    MFHPortfolioExpandOpts,
    MfhPortfolioType,
} from '@condo/domain';
import { getClient } from '../api-client';

export const fetchMfhList = async (
    filter: IMfhFilter,
    expandOptions?: MFHExpandOpts,
): Promise<{
    mfhs: IMFH[];
    total: number;
}> =>
    getClient('basecamp')
        .get('/mfh', { params: { ...filter, expand: expandOptions } })
        .then(response => response.data);

export const fetchMfhSourcingList = async (
    filter: IMfhFilter,
): Promise<{
    mfhs: IMFH[];
    total: number;
}> =>
    getClient('basecamp')
        .get('/mfh-sourcing-list', { params: filter })
        .then(response => response.data);

export const fetchEligibleAssetsList = async (
    filter: IMfhFilter,
): Promise<{
    mfhs: IMFH[];
    total: number;
}> =>
    getClient('basecamp')
        .get('/eligible-assets', { params: filter })
        .then(response => response.data);

export const fetchOneMfh = async (mfhId: number, expandOptions?: MFHExpandOpts): Promise<IMFH> =>
    getClient('basecamp')
        .post(`/mfh/${mfhId}`, {
            expand: expandOptions,
        })
        .then(response => response.data);

export const getMfhEstates = async (mfhId: number, expandOptions: EstateExpandOpts): Promise<IEstate[]> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/estates`, {
            params: {
                expand: expandOptions,
            },
        })
        .then(response => response.data);

export const getMfhLocations = async (mfhId: number, expandOptions: LocationExpandOpts): Promise<ILocation[]> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/locations`, {
            params: {
                expand: expandOptions,
            },
        })
        .then(response => response.data);

export const createMFH = async (body: { mfh: Partial<IMFH>; linkableMedia?: IMediaFile[] }): Promise<IMFH> =>
    getClient('basecamp')
        .post('/mfh', body)
        .then(response => response.data);

export const createMFHEstate = async (mfhBuildingId: number, locationId: number): Promise<IEstate> =>
    getClient('basecamp')
        .post(`/mfh/buildings/${mfhBuildingId}/estates`, { locationId })
        .then(response => response.data.estate);

export const updateMFH = async (mfhID: number, body: Partial<IMFH>): Promise<IMFH> =>
    getClient('basecamp')
        .patch(`/mfh/${mfhID}`, body)
        .then(response => response.data);

export const upsertMFHBuildingLocation = async (mfhBuildingId: number, location: ILocationDTO): Promise<void> =>
    getClient('basecamp').put(`/mfh/buildings/${mfhBuildingId}/locations`, { location });

export const upsertMFHBuilding = async (mfhId: number, mfhBuilding: Partial<IMFHBuilding>): Promise<IMFHBuilding> =>
    getClient('basecamp')
        .put(`/mfh/${mfhId}/buildings/`, { mfhBuilding })
        .then(res => res.data.mfhBuilding);

export const deleteMFHBuilding = async (mfhId: number, mfhBuildingId: number): Promise<IMFHBuilding> =>
    getClient('basecamp').delete(`/mfh/${mfhId}/buildings/${mfhBuildingId}`);

export const deleteMFHBuildingLocation = async (mfhBuildingId: number, locationId: number): Promise<void> =>
    getClient('basecamp').delete(`/mfh/buildings/${mfhBuildingId}/locations/${locationId}`);

export const getMfhDDQuestions = async <const TSchema extends Array<FormSchema> = Array<FormSchema>>(
    mfhId: number,
    type: TSchema,
): Promise<IDDQuestion<TSchema extends Array<infer U> ? U : never>[]> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/dd/questions`, { params: { type } })
        .then(response => response.data.questions);

export const upsertMfhDDQuestion = async <const TSchema extends Array<FormSchema> = Array<FormSchema>>(
    question: IBaseDDQuestion,
): Promise<IDDQuestion<TSchema extends Array<infer U> ? U : never>[]> =>
    getClient('basecamp')
        .post(`/mfh/${question.mfhId}/dd/questions`, question)
        .then(response => response.data.questions);

export const deleteMfhDDQuestion = async <const TSchema extends Array<FormSchema> = Array<FormSchema>>(
    mfhId: number,
    questionId: number,
): Promise<IDDQuestion<TSchema extends Array<infer U> ? U : never>[]> =>
    getClient('basecamp')
        .delete(`/mfh/${mfhId}/dd/questions/${questionId}`)
        .then(response => response.data.questions);

export const updateMfhDDAnswer = async (mfhId: number, data: IDDAnswerUpsertParams): Promise<IDDAnswer> =>
    getClient('basecamp')
        .put(`/mfh/${mfhId}/dd/answers`, data)
        .then(response => response.data.answer);

export const getMfhAttributesFromInfas = async (mfhId: number): Promise<void> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/infas-attributes`)
        .then(response => response.data);

export const updateRentRollDD = async (estateId: number, body: Pick<IUpdateRentRollDDParams, 'attributes' | 'rentalContract'>): Promise<void> =>
    getClient('basecamp')
        .patch(`/mfh/estates/${estateId}/rent-roll-dd`, body)
        .then(response => response.data);

export const fetchMfhPortfolioList = async (
    query: Partial<IMFHPortfolio>,
    expand: MFHPortfolioExpandOpts,
    pagination?: IPaginationFields,
): Promise<{
    mfhPortfolios: IMFHPortfolio[];
    total: number;
}> =>
    getClient('basecamp')
        .get('/mfh-portfolios', { params: { query, expand, pagination } })
        .then(response => response.data);

export const fetchOneMfhPortfolio = async (mfhPortfolioId: number, expandOptions?: MFHPortfolioExpandOpts): Promise<IMFHPortfolio> =>
    getClient('basecamp')
        .get(`/mfh-portfolio/${mfhPortfolioId}`, {
            params: {
                expand: expandOptions,
            },
        })
        .then(response => response.data);

export const createMFHPortfolio = async (payload: { name: string; type: MfhPortfolioType; mfhIds: number[]; assetPartnerId: number }): Promise<IMFHPortfolio> =>
    getClient('basecamp')
        .post('/mfh-portfolio', payload)
        .then(response => response.data);

export const updateMFHPortfolio = async (
    mfhPortfolioId: number,
    payload: {
        name?: string;
        type?: MfhPortfolioType;
        mfhIds?: number[];
    },
): Promise<IMFHPortfolio> =>
    getClient('basecamp')
        .patch(`/mfh-portfolio/${mfhPortfolioId}`, payload)
        .then(response => response.data);

export const fetchMfhBuilding = async (mfhBuildingId: number, expand: MFHBuildingExpandOpts): Promise<IMFHBuilding> =>
    getClient('basecamp')
        .get(`/mfh/buildings/${mfhBuildingId}`, { params: { expand } })
        .then(response => response.data.mfhBuilding);

export const fetchMfhBuildings = async (mfhId: number, expand: MFHBuildingExpandOpts): Promise<IMFHBuilding[]> =>
    getClient('basecamp')
        .get(`/mfh/${mfhId}/buildings`, { params: { expand } })
        .then(response => response.data.mfhBuildings);
