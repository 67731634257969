import { getSignedUrl } from '@/api/basecamp.api';
import {
    deleteMediaFiles,
    downloadSelectionAsPdf,
    getMediaFiles,
    linkMediaFile,
    updateMediaFile,
    updateMediaFilesCategory,
    updateMediaFilesTags,
} from '@/api/media.api';
import { store } from '@/store';
import {
    type ICategoryFacets,
    type IDocUploadResult,
    type IFetchMediaFileParams,
    type IMediaFile,
    type IMediaFileParams,
    type IUpdateMediaFilesTagsQuery,
    MediaFileCategory,
} from '@condo/domain';
import { downscaleImageFiles } from '@condo/helpers';
import axios from 'axios';
import { omit, uniqBy } from 'lodash-es';
import { showMessage } from '../../shared/messages';

export const DISPLAYABLE_MEDIA_FACETS = [
    MediaFileCategory.Listing,
    MediaFileCategory.Inspection,
    MediaFileCategory.PreRenovation,
    MediaFileCategory.Renovation,
    MediaFileCategory.PostRenovation,
    MediaFileCategory.PropertyManagement,
    MediaFileCategory.Livings,
    MediaFileCategory.Other,
];

export const MFH_MEDIA_FACETS = [MediaFileCategory.Listing, MediaFileCategory.PreRenovation, MediaFileCategory.Renovation, MediaFileCategory.Other];

export interface IMediaStore {
    mediaFiles: IMediaFile[];
    categoryFacets: ICategoryFacets;
}

function getInitialState(): IMediaStore {
    return {
        mediaFiles: [],
        categoryFacets: DISPLAYABLE_MEDIA_FACETS.reduce((acc, cat) => ({ ...acc, [cat]: 0 }), {} as Record<MediaFileCategory, number>),
    };
}

// initial state
const state = getInitialState();

const getters = {
    all(state: IMediaStore): IMediaFile[] {
        return state.mediaFiles;
    },
    categoryFacets(state: IMediaStore): ICategoryFacets {
        return state.categoryFacets;
    },
};

export interface IUploadData {
    fileOrFiles: File | File[];
    params: IMediaFileParams;
}

const uploadLocallyAndLink = async (file: File, params: IMediaFileParams): Promise<IDocUploadResult<IMediaFile>[]> => {
    const { bucketFilename, url } = await getSignedUrl();

    await axios
        .create({
            headers: {
                'Content-Type': file.type,
                'Access-Control-Allow-Origin': '*',
            },
        })
        .put(url, file);

    return linkMediaFile({
        ...params,
        bucketFilename,
        originalFileName: file.name,
    });
};

const mediaFileUpload = async ({ fileOrFiles, params }: IUploadData): Promise<IDocUploadResult<IMediaFile>[]> => {
    const config = store.getters['generalData/config'];
    const maxSize = config.IMAGE_RESIZER_MAX_SIZE;

    const downscaledImageFiles = await downscaleImageFiles(fileOrFiles, maxSize);

    if (downscaledImageFiles.length === 0) {
        return;
    }

    showMessage("Hold tight! We're in the middle of the upload, please do not leave the page.");

    const fileUploadResults = await Promise.all(downscaledImageFiles.map(file => uploadLocallyAndLink(file, params)));

    return fileUploadResults.flat().filter(Boolean);
};

const actions = {
    async downloadSelectionAsPdf(_, { mediaFileIds, fileName, estateId }) {
        return downloadSelectionAsPdf({ fileName, mediaFileIds, estateId });
    },
    async fetchMediaFiles({ commit }, params: IFetchMediaFileParams) {
        return getMediaFiles(params).then(({ mediaFiles, categoryFacets }) => {
            commit(
                'setMediaFiles',
                mediaFiles.map(file => omit(file, 'fileBuffer')),
            );
            commit('setCategoryFacets', categoryFacets ?? {});
            return mediaFiles;
        });
    },
    async uploadMediaFiles({ commit }, uploadData: IUploadData): Promise<IMediaFile[]> {
        const uploadResults = await mediaFileUpload(uploadData);

        const mediaFiles = uploadResults.map(({ file }) => omit(file, 'fileBuffer'));
        commit('addMediaFiles', mediaFiles);
        return mediaFiles;
    },
    async updateFilesTags(_, data: IUpdateMediaFilesTagsQuery) {
        await updateMediaFilesTags(data);
    },
    async updateFilesCategory(_, data: { mediaFileIds: number[]; category: MediaFileCategory }) {
        await updateMediaFilesCategory(data);
    },
    async updateFile(_, { mediaId, data }: { mediaId: number; data: Partial<IMediaFile> }) {
        await updateMediaFile({ mediaId, data: { ...data } });
    },
    async deleteMediaFiles({ commit }, mediaFileIds: number[]) {
        await deleteMediaFiles({ mediaFileIds });
        commit('removeMediaFiles', mediaFileIds);
    },
};

const mutations = {
    setCategoryFacets(state: IMediaStore, categoryFacets: ICategoryFacets) {
        const filteredFacets = Object.entries(categoryFacets).reduce((acc, [facet, num]) => {
            return { ...acc, ...(DISPLAYABLE_MEDIA_FACETS.includes(facet as MediaFileCategory) ? { [facet]: num } : {}) };
        }, {} as ICategoryFacets);
        state.categoryFacets = filteredFacets;
    },

    setMediaFiles(state: IMediaStore, mediaFiles: IMediaFile[]) {
        state.mediaFiles = mediaFiles;
    },
    removeMediaFiles(state: IMediaStore, mediaFileIds: number[]) {
        state.mediaFiles = state.mediaFiles.filter(file => !mediaFileIds.includes(file.mediaFileId));
    },
    addMediaFiles(state: IMediaStore, mediaFiles: IMediaFile[]) {
        state.mediaFiles = uniqBy([...state.mediaFiles, ...mediaFiles], ({ uuid }) => uuid);
    },
};

export const mediaStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
