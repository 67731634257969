import { getStrippedCCSRoutes } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';
import { getDefaultFilterQuery } from './route-helpers';

const CCSRouteMap = getStrippedCCSRoutes();

export const financeRoutes: RouteRecordRaw[] = [
    {
        ...CCSRouteMap['finance.transaction-rules.list'],
        component: () => import('@/views/TransactionReconcilerRules.vue'),
    },
    {
        ...CCSRouteMap['finance.payment-notices.list'],
        component: () => import('@/components/payment-notice-summary/PaymentNoticeSummaryList.vue'),
        props: route => ({ filter: getDefaultFilterQuery(route.query) }),
    },
];
